import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const MediaPressMaterials = ({ data }) => {
  const lang = "en"

  return (
    <Layout lang={lang}>
      <SEO title="Media and press materials" />

      <section className="media-top">
        <div className="container smaller">
          <h1>Media and press materials</h1>
          <p>
            Brand assets and media for promotional purposes. All materials can
            be downloaded here and used by press and media with reference to
            Hiddenfjord.
          </p>
        </div>
      </section>

      <section className="media media-relases">
        <div className="container smaller">
          <h2>Press releases</h2>
          <span>
            Atlantic Salmon Farmer, Becomes the Industry’s First to Stop Using
            Air Freight
          </span>
          <span>19-Nov-2020</span>
          <div className="row justify-content-center">
            {data?.acfMediaPress?.pressReleases?.map((item, index) => (
              <div className="col-md-5 offset-md-1" key={index}>
                <div className="media-relases__pdf media-relases__pdf--pdf">
                  <p>{item.title}</p>
                  <a
                    href={item.file.localFile.publicURL}
                    className="media__link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download
                  </a>
                </div>
              </div>
            ))}
          </div>
          <div className="row justify-content-center row--last">
            <div className="col-md-7">
              <h2>Fact sheet about Hiddenfjord</h2>
              {data?.acfMediaPress?.factSheets?.map((item, index) => (
                <div
                  className="media-relases__pdf media-relases__pdf--pdf media-relases__pdf--pdf-last"
                  key={index}
                >
                  <p>{item.title}</p>
                  <a
                    href={item.file.localFile.publicURL}
                    className="media__link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download
                  </a>
                </div>
              ))}
            </div>
          </div>
          <hr />
        </div>
      </section>

      <section className="media media-infographics">
        <div className="container smaller">
          <h2>Infographics</h2>
          <div className="row justify-content-center">
            {data?.acfMediaPress?.infographics?.map((item, index) => (
              <div
                className={index === 0 ? "col-md-12" : "col-md-5"}
                key={index}
              >
                <div className="media-infographics__item">
                  <img
                    className="img-fluid"
                    src={item.image.localFile.publicURL}
                    alt=""
                  />
                  <div>
                    {item.title && <p>{item.title}</p>}
                    <a
                      href={item.file.localFile.publicURL}
                      className="media__link"
                      download
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <hr />
        </div>
      </section>

      <section className="media media-people">
        <div className="container smaller">
          <h2>people pictures</h2>
          <p className="media__subtitle">Photos by: Súsanna Johansen</p>
          <div className="row justify-content-center">
            {data?.acfMediaPress?.peoplePictures?.map((item, index) => (
              <div className="col-md-4" key={index}>
                <div className="media-people__item">
                  <img
                    className="img-fluid"
                    src={item.image.localFile.publicURL}
                    alt=""
                  />
                  <div>
                    <p>{item.title}</p>
                    <a
                      href={item.file.sourceUrl}
                      className="media__link"
                      target="_blank"
                      rel="noreferrer"
                      download
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <hr />
        </div>
      </section>

      <section className="media media-pictures">
        <div className="container smaller">
          <h2>salmon pictures</h2>
          <p className="media__subtitle">Photos by: Mikkel Adsbøl</p>
          <div className="row justify-content-center">
            {data?.acfMediaPress?.salmonPictures?.map((item, index) => (
              <div className="col-md-4" key={index}>
                <div className="media-pictures__item">
                  <img
                    className="img-fluid"
                    src={item.image.localFile.publicURL}
                    alt=""
                  />
                  <div>
                    <a
                      href={item.file.sourceUrl}
                      className="media__link"
                      target="_blank"
                      rel="noreferrer"
                      download
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <hr />
        </div>
      </section>

      <section className="media media-pictures">
        <div className="container smaller">
          <h2>salmon farm pictures</h2>
          <p className="media__subtitle">Photos by: Rannvá Joensen</p>
          <div className="row justify-content-center">
            {data?.acfMediaPress?.salmonFarmPictures?.map((item, index) => (
              <div className="col-md-4" key={index}>
                <div className="media-pictures__item">
                  <img
                    className="img-fluid"
                    src={item.image.localFile.publicURL}
                    alt=""
                  />
                  <div>
                    <a
                      href={item.file.sourceUrl}
                      className="media__link"
                      target="_blank"
                      rel="noreferrer"
                      download
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <hr />
        </div>
      </section>

      <section className="media media-video">
        <div className="container smaller">
          <h2>Video</h2>
          <div className="row justify-content-center">
            {data?.acfMediaPress?.videos?.map((item, index) => (
              <div className="col-md-4" key={index}>
                <div className="media-video__item">
                  <img
                    className="img-fluid"
                    src={item.poster.localFile.publicURL}
                    alt=""
                  />
                  <div>
                    <p>{item.title}</p>
                    <a
                      href={`https://wp.hiddenfjord.com${item.videoFile.mediaItemUrl}`}
                      className="media__link"
                      target="_blank"
                      rel="noreferrer"
                      download
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <hr />
        </div>
      </section>

      <section className="media media-about about">
        <div className="container smaller">
          <p className="about__title">
            For more press inquiries please contact:
          </p>
          <div className="contact-container">
            <p className="contact-container__name">Anna Ditchev</p>
            <p className="contact-container__company">APCO Worldwide</p>
            <a href="mailto:aditchev@apcoworldwide.com ">
              aditchev@apcoworldwide.com {" "}
            </a>
            <a href="tel:+17757716848">+1 775 771 6848</a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        data: wpPage(id: { eq: "cG9zdDoyMjA5" }) {
          acfMediaPress {
            pressReleases {
              title
              file {
                localFile {
                  publicURL
                }
              }
            }
            factSheets {
              title
              file {
                localFile {
                  publicURL
                }
              }
            }
            infographics {
              image {
                localFile {
                  publicURL
                }
              }
              title
              file {
                localFile {
                  publicURL
                }
              }
            }
            peoplePictures {
              image {
                localFile {
                  publicURL
                }
              }
              title
              file {
                sourceUrl
              }
            }
            salmonPictures {
              image {
                localFile {
                  publicURL
                }
              }
              file {
                sourceUrl
              }
            }
            salmonFarmPictures {
              image {
                localFile {
                  publicURL
                }
              }
              file {
                sourceUrl
              }
            }
            videos {
              title
              poster {
                localFile {
                  publicURL
                }
              }
              videoFile {
                mediaItemUrl
              }
            }
          }
        }
      }
    `}
    render={data => <MediaPressMaterials {...data} />}
  />
)
